import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveDirective as _resolveDirective, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createTextVNode as _createTextVNode, withKeys as _withKeys, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "overflow-hidden px-15 h-full py-5" }
const _hoisted_2 = {
  key: 0,
  class: "ticket-id bg-white"
}
const _hoisted_3 = ["placeholder", "readonly", "tabindex"]
const _hoisted_4 = { class: "flex items-center gap-10 mx-10 ticket-form-header-btns" }
const _hoisted_5 = ["tabindex"]
const _hoisted_6 = {
  class: "btn options-button flex-inline-center ticket-info-button",
  "data-showtippyonclick": true
}
const _hoisted_7 = {
  key: 0,
  class: "overflow-hidden overflow-y-aut ticket-form-content ticket-form-compact"
}
const _hoisted_8 = { class: "mt-20 p-2 form-editable2 pr-15" }
const _hoisted_9 = { class: "form-section-content" }
const _hoisted_10 = {
  key: 0,
  class: "form-section-separator"
}
const _hoisted_11 = { class: "flex gap-10" }
const _hoisted_12 = {
  key: 0,
  class: "ml-auto p-20"
}
const _hoisted_13 = {
  key: 2,
  class: "flex gap-10"
}
const _hoisted_14 = {
  key: 1,
  class: "mt-15 flex items-center font-12 ticket-readonly hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_menu_ticket = _resolveComponent("menu-ticket")!
  const _component_ticket_color_picker = _resolveComponent("ticket-color-picker")!
  const _component_ticket_base_assigned_to = _resolveComponent("ticket-base-assigned_to")!
  const _component_ticket_base_description = _resolveComponent("ticket-base-description")!
  const _component_ticket_base_email_notify = _resolveComponent("ticket-base-email_notify")!
  const _component_ticket_base_required_by = _resolveComponent("ticket-base-required_by")!
  const _component_ticket_base_stage = _resolveComponent("ticket-base-stage")!
  const _component_ticket_base_tags = _resolveComponent("ticket-base-tags")!
  const _component_notes_panel = _resolveComponent("notes-panel")!
  const _component_content_panels = _resolveComponent("content-panels")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-col h-full overflow-hidden wrap-container ticket-wrap-container", {loading: _ctx.loadingScreenData}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_content_panels, {
        screen: "ticket",
        class: "screen-container-ticket"
      }, {
        leftPanel: _withCtx(() => [
          (_ctx.ticket)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                ref: "refScrollableContainerLeftPanel",
                class: _normalizeClass([{
                'ticket-form-readonly': _ctx.isReadonlyTicket,
                'scroll-indicator-top': _ctx.scrollTopLeftPanel > 0,
                'scroll-indicator-bottom': _ctx.scrollTopLeftPanel > 0 && !_ctx.arrivedStateLeftPanel.bottom
              }, "left-panel-content scroll-indicator h-full overflow-auto"]),
                onKeydown: [
                  _cache[18] || (_cache[18] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.saveTicket && _ctx.saveTicket(...args)), ["meta","prevent"]), ["enter"])),
                  _cache[19] || (_cache[19] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.saveTicket && _ctx.saveTicket(...args)), ["ctrl","prevent"]), ["enter"]))
                ]
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex h-50 flex-shrink-0 items-center overflow-hidden pl-6 pt-4 relative ticket-title-wrap sticky top-0 z-index-12 bg-white", {
                'has-changes': _ctx.isShowDevTools && _ctx.hasChanges
              }])
                }, [
                  _createElementVNode("button", {
                    class: "back-button mr-10",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.goBack()), ["prevent"]))
                  }, [
                    _createVNode(_component_svg_icon, {
                      name: 'arrow',
                      width: "10",
                      height: "15"
                    })
                  ]),
                  (_ctx.isShowTicketId)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, "ID: " + _toDisplayString(_ctx.ticket.id), 1))
                    : _createCommentVNode("", true),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    ref: "refTicketTitle",
                    class: _normalizeClass(["truncate pl-8 font-21 h-38 leading-static-38 w-full resize-none transparent ticket-form-title", {
                  loading: _ctx.loadingScreenData,
                  'pointer-events-none': _ctx.isReadonlyTicket,
                }]),
                    placeholder: _ctx.t('Enter ticket name'),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ticket.title) = $event)),
                    readonly: _ctx.isReadonlyTicket,
                    tabindex: _ctx.isReadonlyTicket ? -1 : null,
                    onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removeFieldError('title')))
                  }, null, 42, _hoisted_3), [
                    [_vModelText, _ctx.ticket.title]
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("button", {
                      ref: "refColorPickerButton",
                      class: _normalizeClass(["flex-inline-center btn-change-color", {
                    ['bc_'+(_ctx.ticket.tmpColor || _ctx.ticket.color)]: true,
                    dark: parseFloat(_ctx.ticket.tmpColor || _ctx.ticket.color) >= _ctx.minDarkColor,
                    'pointer-events-none': _ctx.isReadonlyTicket,
                    'hide-if-narrow-form': !_ctx.isNewTicket
                  }]),
                      tabindex: _ctx.isReadonlyTicket ? -1 : null,
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.triggerHandlerColorPicker && _ctx.triggerHandlerColorPicker(...args)))
                    }, [
                      _createVNode(_component_svg_icon, {
                        name: 'edit',
                        width: "15",
                        height: "16"
                      })
                    ], 10, _hoisted_5),
                    _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_6, [
                      _createVNode(_component_svg_icon, {
                        name: 'i',
                        width: "26",
                        height: "16"
                      })
                    ])), [
                      [_directive_tippy, {
                    content: _ctx.eventLogTooltip,
                    allowHTML: true,
                    appendTo: _ctx.eventLogTooltipContainer,
                    interactive: true,
                    placement: 'bottom',
                    maxWidth: 'none',
                  }]
                    ]),
                    (!_ctx.isNewTicket)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "btn options-button flex-inline-center",
                          ref: "refTicketMenuButton",
                          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.ticketMenuButtonClickHandler && _ctx.ticketMenuButtonClickHandler(...args)))
                        }, [
                          _createVNode(_component_svg_icon, {
                            name: 'three-dots',
                            width: "12",
                            height: "10"
                          })
                        ], 512))
                      : _createCommentVNode("", true),
                    (_ctx.isExistTicketMenu && !_ctx.isNewTicket)
                      ? (_openBlock(), _createBlock(_component_menu_ticket, {
                          key: 1,
                          ref: "refTicketMenu",
                          "position-target": _ctx.refTicketMenuButton,
                          type: "ticket",
                          id: _ctx.ticket.id,
                          name: _ctx.ticket.title,
                          color: _ctx.ticket.color,
                          "color-item-hidden": _ctx.isColorPickerButtonVisible,
                          archived: !!_ctx.ticket.archived,
                          readonly: _ctx.isReadonlyTicket || !_ctx.ticket.isEdit,
                          "is-new": _ctx.isNewTicket,
                          onAction: _ctx.ticketMenuActionHandler,
                          onPreviewColor: _ctx.previewColorMenuHandler,
                          onChangeColor: _ctx.changeColorMenuHandler,
                          onRevertColor: _ctx.revertColorMenuHandler
                        }, null, 8, ["position-target", "id", "name", "color", "color-item-hidden", "archived", "readonly", "is-new", "onAction", "onPreviewColor", "onChangeColor", "onRevertColor"]))
                      : _createCommentVNode("", true),
                    (!_ctx.isReadonlyTicket && _ctx.isExistColorPicker)
                      ? (_openBlock(), _createBlock(_component_ticket_color_picker, {
                          key: 2,
                          ref: "refColorPicker",
                          "position-target": _ctx.refColorPickerButton,
                          placement: "bottom-start",
                          offset: 10,
                          extraClass: "picker picker-color",
                          curColor: _ctx.ticket.color,
                          onColorOver: _ctx.previewColor,
                          onColorClick: _ctx.changeColor,
                          onPickerOut: _ctx.revertColor,
                          onPickerClose: _ctx.revertColor
                        }, null, 8, ["position-target", "curColor", "onColorOver", "onColorClick", "onPickerOut", "onPickerClose"]))
                      : _createCommentVNode("", true),
                    _withDirectives((_openBlock(), _createElementBlock("button", {
                      class: "btn options-button flex-inline-center scroll-btn folded",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.scrollToNotes()))
                    }, [
                      _createVNode(_component_svg_icon, {
                        name: 'arrow',
                        class: "rotate-90",
                        width: "9"
                      })
                    ])), [
                      [_directive_tippy, {
                    offset: [0, 15],
                    content: _ctx.t('scroll to Notes')
                  }]
                    ])
                  ])
                ], 2),
                (_ctx.schemaByRows)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['main', 'advanced'], (section) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: section }, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["form-section", ['form-section-' + section, section === 'advanced' && _ctx.isAdvancedSectionExpanded ? 'form-section-advanced-expanded' : '']])
                            }, [
                              _createElementVNode("div", _hoisted_9, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.schemaByRows[section], (schemaRow, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: index,
                                    class: "form-row"
                                  }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(schemaRow, (schemaItem) => {
                                      return (_openBlock(), _createElementBlock(_Fragment, {
                                        key: schemaItem.name
                                      }, [
                                        (schemaItem.name === 'assigned_to')
                                          ? (_openBlock(), _createBlock(_component_ticket_base_assigned_to, {
                                              key: 0,
                                              ref_for: true,
                                              ref: el => { if (el) _ctx.formFieldsBase[schemaItem.name] = el },
                                              modelValue: _ctx.ticket.ticket_users,
                                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.ticket.ticket_users) = $event)),
                                              readonly: _ctx.isReadonlyTicket,
                                              "ticket-id": _ctx.ticketId,
                                              "project-id": _ctx.ticket.flow_id
                                            }, null, 8, ["modelValue", "readonly", "ticket-id", "project-id"]))
                                          : (schemaItem.name === 'description')
                                            ? (_openBlock(), _createBlock(_component_ticket_base_description, {
                                                key: 1,
                                                ref_for: true,
                                                ref: el => { if (el) _ctx.formFieldsBase[schemaItem.name] = el },
                                                modelValue: _ctx.ticket.description,
                                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.ticket.description) = $event)),
                                                readonly: _ctx.isReadonlyTicket,
                                                "ticket-id": _ctx.ticketId,
                                                onMention: _ctx.descriptionMentionHandler
                                              }, null, 8, ["modelValue", "readonly", "ticket-id", "onMention"]))
                                            : (schemaItem.name === 'email_notify')
                                              ? (_openBlock(), _createBlock(_component_ticket_base_email_notify, {
                                                  key: 2,
                                                  ref_for: true,
                                                  ref: el => { if (el) _ctx.formFieldsBase[schemaItem.name] = el },
                                                  modelValue: _ctx.ticket.email_notify,
                                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.ticket.email_notify) = $event)),
                                                  readonly: _ctx.isReadonlyTicket
                                                }, null, 8, ["modelValue", "readonly"]))
                                              : (schemaItem.name === 'required_by')
                                                ? (_openBlock(), _createBlock(_component_ticket_base_required_by, {
                                                    key: 3,
                                                    ref_for: true,
                                                    ref: el => { if (el) _ctx.formFieldsBase[schemaItem.name] = el },
                                                    modelValue: _ctx.ticket.deadline_date,
                                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.ticket.deadline_date) = $event)),
                                                    readonly: _ctx.isReadonlyTicket
                                                  }, null, 8, ["modelValue", "readonly"]))
                                                : (schemaItem.name === 'stage')
                                                  ? (_openBlock(), _createBlock(_component_ticket_base_stage, {
                                                      key: 4,
                                                      ref_for: true,
                                                      ref: el => { if (el) _ctx.formFieldsBase[schemaItem.name] = el },
                                                      modelValue: _ctx.ticket.flow_step_id,
                                                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.ticket.flow_step_id) = $event)),
                                                      "flow-steps": _ctx.flowSteps,
                                                      readonly: _ctx.isReadonlyTicket
                                                    }, null, 8, ["modelValue", "flow-steps", "readonly"]))
                                                  : (schemaItem.name === 'tags')
                                                    ? (_openBlock(), _createBlock(_component_ticket_base_tags, {
                                                        key: 5,
                                                        ref_for: true,
                                                        ref: el => { if (el) _ctx.formFieldsBase[schemaItem.name] = el },
                                                        modelValue: _ctx.ticketTags,
                                                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.ticketTags) = $event)),
                                                        selectedMaxCount: 8,
                                                        readonly: _ctx.isReadonlyTicket
                                                      }, null, 8, ["modelValue", "readonly"]))
                                                    : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getFieldComponentName(schemaItem)), {
                                                        key: 6,
                                                        ref_for: true,
                                                        ref: el => { if (el) _ctx.formFieldsCustom[schemaItem.name] = el },
                                                        field: schemaItem,
                                                        ticket: _ctx.ticket,
                                                        readonly: _ctx.isReadonlyTicket,
                                                        "data-value": _ctx.customFieldsValues[schemaItem.name],
                                                        modelValue: _ctx.customFieldsValues[schemaItem.name],
                                                        "onUpdate:modelValue": ($event: any) => ((_ctx.customFieldsValues[schemaItem.name]) = $event),
                                                        "data-fieldname": schemaItem.name,
                                                        class: _normalizeClass({
                              'form-item-half': schemaRow.length === 1 && schemaItem.pos_type === 'single',
                              'required': schemaItem.required,
                            })
                                                      }, null, 8, ["field", "ticket", "readonly", "data-value", "modelValue", "onUpdate:modelValue", "data-fieldname", "class"]))
                                      ], 64))
                                    }), 128))
                                  ]))
                                }), 128))
                              ])
                            ], 2),
                            (section === 'main' && _ctx.schemaByRows.advanced && _ctx.schemaByRows.advanced.length)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                  _createElementVNode("span", {
                                    onMousedown: _cache[12] || (_cache[12] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleAdvancedSection && _ctx.toggleAdvancedSection(...args)), ["prevent"]))
                                  }, [
                                    _createTextVNode(_toDisplayString(_ctx.t('expanded form')), 1),
                                    _createVNode(_component_svg_icon, {
                                      name: 'arrow',
                                      width: "6",
                                      height: "10"
                                    })
                                  ], 32)
                                ]))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 64))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass(["flex mr-15 items-center justify-between pt-10 px-8 pb-20 ticket-form-buttons ml-2 mr-2", {
                'without-buttons-divider': !_ctx.isButtonsDividerNeeded
              }])
                }, [
                  _createElementVNode("div", _hoisted_11, [
                    (!_ctx.isNewTicket)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _withDirectives((_openBlock(), _createElementBlock("button", {
                            class: "btn options-button -rotate-180",
                            onClick: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (_ctx.gotoTicket('prev')), ["prevent"]))
                          }, [
                            _createVNode(_component_svg_icon, {
                              name: 'arrow',
                              width: "9",
                              class: "ml-2"
                            })
                          ])), [
                            [_directive_tippy, {
                        content: _ctx.t('Previous Ticket'),
                        offset: [0, 13],
                      }]
                          ]),
                          _withDirectives((_openBlock(), _createElementBlock("button", {
                            class: "btn options-button",
                            onClick: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (_ctx.gotoTicket('next')), ["prevent"]))
                          }, [
                            _createVNode(_component_svg_icon, {
                              name: 'arrow',
                              width: "9",
                              class: "ml-2"
                            })
                          ])), [
                            [_directive_tippy, {
                        content: _ctx.t('Next Ticket'),
                        offset: [0, 13],
                      }]
                          ])
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.isReadonlyTicket)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("strong", null, _toDisplayString(_ctx.t('You can not edit this ticket')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.isReadonlyTicket)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        class: "btn btn-reset btn-transparent-with-border",
                        onClick: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.cancelTicket && _ctx.cancelTicket(...args)), ["prevent"]))
                      }, _toDisplayString(_ctx.t('Close')), 1))
                    : _createCommentVNode("", true),
                  (!_ctx.isReadonlyTicket)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createElementVNode("button", {
                          class: "btn btn-link",
                          onClick: _cache[16] || (_cache[16] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.cancelTicket && _ctx.cancelTicket(...args)), ["prevent"]))
                        }, _toDisplayString(_ctx.t('Cancel')), 1),
                        _withDirectives((_openBlock(), _createElementBlock("span", null, [
                          _createElementVNode("button", {
                            ref: "refSaveBtn",
                            class: _normalizeClass(["btn btn-default", {
                'loading pointer-events-none': _ctx.isSavingTicket,
                'loaded pointer-events-none': _ctx.isSavedTicket
              }]),
                            onClick: _cache[17] || (_cache[17] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.saveTicket && _ctx.saveTicket(...args)), ["prevent"]))
                          }, _toDisplayString(_ctx.t('Save')), 3)
                        ])), [
                          [_directive_tippy, {
                content: _ctx.t('Use CTRL+click to Save without leaving this page'),
              }]
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 2),
                (!_ctx.isNewTicket)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14))
                  : _createCommentVNode("", true)
              ], 34))
            : _createCommentVNode("", true)
        ]),
        rightPanel: _withCtx(() => [
          _createElementVNode("div", {
            ref: "refScrollableContainerRightPanel",
            class: _normalizeClass(["right-panel-content scroll-indicator h-full overflow-auto pl-4 pr-10 pt-0 pb-20", {
              'scroll-indicator-top': _ctx.scrollTopRightPanel > 0,
              'scroll-indicator-bottom': _ctx.scrollTopRightPanel > 0 && !_ctx.arrivedStateRightPanel.bottom
            }])
          }, [
            _createVNode(_component_notes_panel, {
              ref: "refNotesPanel",
              screen: "ticket",
              ticket: _ctx.ticketId,
              "is-loading": _ctx.loadingScreenData,
              onNoteSaved: _ctx.noteSavedHandler
            }, null, 8, ["ticket", "is-loading", "onNoteSaved"])
          ], 2)
        ]),
        _: 1
      })
    ])
  ], 2))
}